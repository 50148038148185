@media only screen and (max-width: 600px) {
  #fc_frame:not(.fcFrameBottom),
  #fc_frame.fc-widget-normal:not(.fcFrameBottom) {
    bottom: 75px !important;
    right: 5px !important;
  }
}

.fcFrameBottom {
  bottom: 40px !important;
}

@media screen and (max-width: 930px) {
  .fcFrameBottom {
    bottom: 60px !important;
  }
}
@media screen and (max-width: 550px) {
  .fcFrameBottom {
    bottom: 75px !important;
  }
}

@media screen and (max-width: 380px) {
  .fcFrameBottom {
    bottom: 100px !important;
  }
}

@media screen and (max-width: 330px) {
  .fcFrameBottom {
    bottom: 115px !important;
  }
}

.chat-fc-form-outer {
  border-radius: 10px !important;
}

.chat-fc-form-outer .fc-header {
  padding: 18px 15px !important;
}

.chat-fc-form-outer div.fc-form a.fc-button {
  border-radius: 6px !important;
  text-decoration: none !important;
  padding: 10px 0 !important;
}

.chat-fc-form-outer div.fc-form ul li {
  margin-bottom: 23px !important;
}
